import styled from '@emotion/styled'
import {
  ErrorMessage,
  Form,
  RadiobuttonGroup,
  TimeUtil,
} from 'cuenect-web-core'
import parse from 'html-react-parser'
import ReactTooltip from 'react-tooltip'
import { mq } from './../../../utility/break'

import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Checkbox,
  ErrorBar,
  Heading,
  Input,
  Paragraph,
  Radiobutton,
  Select,
  Sideblock,
  SieArrow,
  Table,
  TBody,
  Td,
  Text,
  Textarea,
  Tr,
} from '../../atoms'

import i18n from 'i18next'
import { DialogAppointmentService } from '../../../api/dialogAppointment'
import { dialogConfig } from '../../../config'
import {
  IHm21Appointment,
  IHm21DialogData,
  IHm21DialogState,
} from '../../../interfaces/dialogdata'
import { AppointmentTopics, Topic } from '../../../utility'
import { getAbbr } from './../../../utility'
export type TFormSent = 'sent' | 'error' | undefined

export interface IDialogAppointmentForm {
  token: string
  onSubmit?(data: IHm21DialogData, appointment: IHm21Appointment): void
  onComplete?(success: boolean, error?: string): void
  onReset?: Function
  status?: TFormSent
  error?: string
}

const wrapInput = (
  name: keyof IHm21DialogData,
  element: React.FC,
  // tslint:disable-next-line: no-any
  props: any,
  flex: number | string
) => {
  return (
    <div className="elem" style={{ flex }}>
      {React.createElement(element, { ...props })}
    </div>
  )
}

export const DialogAppointment: React.FC<IDialogAppointmentForm> = props => {
  const { t } = useTranslation('dialogappointment')
  const [sending, setSending] = useState(false)

  const [mainTopics] = React.useState<Topic[]>(
    AppointmentTopics.getMainTopics()
  )

  const [topicLevel1, setTopicLevel1] = useState<string>('')
  const [topicLevel2, setTopicLevel2] = useState<string>('')
  const [topicLevel3, setTopicLevel3] = useState<string>('')

  const [description1, setDescription1] = useState<string>('')
  const [description2, setDescription2] = useState<string>('')
  const [description3, setDescription3] = useState<string>('')
  const [otherTopics, setOtherTopics] = useState<string>('')

  const [startDate, setStartDate] = useState<Date>()
  const [category, setCategory] = useState<string>('')
  const [focus, setFocus] = useState<string[]>([])

  const [appointment, setAppointment] = useState<IHm21Appointment>({
    name: '...',
    participants: [],
    resources: [],
    tags: [],
  })

  const { register, handleSubmit, errors } = useForm<IHm21DialogData>()

  const minOneCheckedFollowUp = () => {
    const checkboxes = document.getElementsByName('followUp') as NodeListOf<
      HTMLInputElement
    >

    return Array.from(checkboxes).some(checkbox => checkbox.checked)
  }

  const isTopicEmpty = (topic: string) => topic.length > 0 && topic !== '0'

  const parseSelect = (
    data: Topic[] | null,
    hasEmpty?: boolean,
    exclude?: string | null,
    addOverview?: boolean
  ) => {
    return (
      <>
        {hasEmpty ? (
          <>
            <option value=""></option>
            {addOverview && <option value="-1">Overview</option>}
          </>
        ) : (
          <></>
        )}
        {data ? (
          data.map(({ label, uuid }) => (
            <option disabled={exclude === uuid} value={label}>
              {label}
            </option>
          ))
        ) : (
          <></>
        )}
      </>
    )
  }

  const getContent = async () => {
    const response: IHm21DialogState = await DialogAppointmentService.getAppointment(
      props.token
    )
    if (response) {
      const a = response.appointment
      setAppointment(a)
      if (a.start) {
        setStartDate(new Date(a.start))
      }
      if (Object.keys(a.tags).length > 0) {
        const f: string[] = []
        a.tags.forEach(v => {
          if (v.startsWith('Category:')) {
            setCategory(v.replace('Category: ', ''))
          } else {
            f.push(v)
          }
        })
        setFocus(f)
      }
    }
  }

  useEffect(() => {
    getContent()
  }, [])

  return (
    <Form
      onSubmit={handleSubmit(data => {
        setSending(true)
        props.onSubmit && props.onSubmit(data, appointment)
      })}
    >
      {!props.status && (
        <>
          <Heading type="h2" spacing={{ top: 4 }}>
            {t('intro.header')}
          </Heading>

          <Table>
            <TBody>
              <Tr>
                <Td
                  noBottomLine
                  size={18}
                  black
                  padding={{ top: 1, left: 0, bottom: 0 }}
                >
                  {t('intro.title')}:
                </Td>
                <Td
                  noBottomLine
                  size={18}
                  padding={{ top: 1, left: 0, bottom: 0 }}
                >
                  {appointment.name}
                </Td>
              </Tr>
              <Tr>
                <Td
                  noBottomLine
                  size={18}
                  black
                  padding={{ top: 0, left: 0, bottom: 0 }}
                >
                  {t('intro.date')}:
                </Td>
                <Td
                  noBottomLine
                  size={18}
                  padding={{ top: 0, left: 0, bottom: 0 }}
                >
                  {startDate
                    ? `${TimeUtil.get(startDate).format('l LT')} ${getAbbr(
                        TimeUtil.getUsersTimezone(),
                        i18n.language,
                        TimeUtil.getNow()
                      )}`
                    : '...'}
                </Td>
              </Tr>
            </TBody>
          </Table>

          <Container>
            {appointment.participants.map(participant => (
              <Sideblock>
                <Text black size={18}>
                  {t('intro.participant')}:
                </Text>
                <Text size={18}>
                  {participant.firstname} {participant.lastname} -{' '}
                  {participant.company}
                </Text>
              </Sideblock>
            ))}
            {appointment?.resources.map(resource => (
              <Sideblock>
                <Text black size={18}>
                  {t('intro.host')}:
                </Text>
                <Text size={18}>
                  {resource.firstname} {resource.lastname}
                </Text>
              </Sideblock>
            ))}
          </Container>

          <Table spacing={{ top: 1 }}>
            <TBody>
              <Tr>
                <Td
                  noBottomLine
                  size={18}
                  black
                  padding={{ top: 1, left: 0, bottom: 0 }}
                >
                  {t('intro.category')}:
                </Td>
                <Td
                  noBottomLine
                  size={18}
                  padding={{ top: 1, left: 0, bottom: 0 }}
                >
                  {category}
                </Td>
              </Tr>

              {!category.startsWith('Expert Talk by Sales') && (
                <Tr>
                  <Td
                    noBottomLine
                    size={18}
                    black
                    padding={{ top: 0, left: 0, bottom: 0 }}
                  >
                    {t('intro.focus')}:
                  </Td>
                  <Td
                    noBottomLine
                    size={18}
                    padding={{ top: 0, left: 0, bottom: 0 }}
                  >
                    {focus.join(' - ')}
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>

          <Heading type="h5" spacing={{ top: 3, bottom: 2 }}>
            {t('registration.header')}
          </Heading>

          <Container>
            <Sideblock>
              <Text black size={18}>
                {t('registration.postregistration')} <sup>*</sup>
              </Text>
              <Subblock>
                {dialogConfig.customerrequest.map((key, i) => {
                  const label = t(`registration.customerrequest.${key}`)

                  return (
                    <BoxItem key={i}>
                      <Checkbox
                        id={key}
                        name="followUp"
                        value={label}
                        label={label}
                        ref={register({
                          required: true,
                          validate: minOneCheckedFollowUp,
                        })}
                      />
                    </BoxItem>
                  )
                })}

                <BoxItem>
                  <Paragraph>
                    {errors['followUp'] && (
                      <ErrorMessage>{t(`errors.followUp`)}</ErrorMessage>
                    )}
                  </Paragraph>
                </BoxItem>
              </Subblock>
            </Sideblock>
            <Sideblock>
              <Text black size={18}>
                {t('registration.customerstatus')} <sup>*</sup>
              </Text>
              <Subblock>
                <RadiobuttonGroup>
                  {dialogConfig.customerproject.map((key, i) => {
                    const label = t(`registration.customerproject.${key}`)

                    return (
                      <BoxItem>
                        <Radiobutton
                          id={key}
                          value={key}
                          name="projectStart"
                          ref={register({ required: true })}
                        >
                          {label}
                        </Radiobutton>
                      </BoxItem>
                    )
                  })}
                </RadiobuttonGroup>

                <BoxItem>
                  <Paragraph>
                    {errors['projectStart'] && (
                      <ErrorMessage>{t(`errors.chooseOne`)}</ErrorMessage>
                    )}
                  </Paragraph>
                </BoxItem>
              </Subblock>
            </Sideblock>
          </Container>

          <Text black size={18} spacing={{ top: 1, bottom: 1 }}>
            {t('registration.details.header')} <sup>*</sup>
          </Text>

          <Text size={18} spacing={{ top: 0.5 }}>
            {t('registration.details.choosetopiclevel')}
          </Text>
          <Container noMargin>
            <Sideblock spacing={{ top: 0.5 }}>
              {wrapInput(
                'topiclevel1',
                Select,
                {
                  placeholder: t('registration.details.topiclevel'),
                  defaultValue: '',
                  ref: register(),
                  name: 'topiclevel1',
                  error: errors[`topiclevel1`] ? 'Invalid Topic Level' : '',
                  onChange: (e: React.FormEvent<HTMLSelectElement>) => {
                    setTopicLevel1(e.currentTarget.value)
                  },
                  children: parseSelect(mainTopics, true),
                },
                1
              )}
            </Sideblock>
            <Sideblock spacing={{ top: 0.5 }}>
              {wrapInput(
                'description1',
                Textarea,
                {
                  placeholder: t('registration.details.description'),
                  defaultValue: '',
                  ref: register(),
                  name: 'description1',
                  disabled: !isTopicEmpty(topicLevel1),
                  rows: 3,
                  error: errors[`description1`] ? ' ' : '',
                  onChange: (e: React.FormEvent<HTMLSelectElement>) => {
                    setDescription1(e.currentTarget.value)
                  },
                },
                1
              )}
            </Sideblock>
          </Container>

          <Container>
            <Sideblock spacing={{ top: 0.5 }}>
              {wrapInput(
                'topiclevel2',
                Select,
                {
                  placeholder: t('registration.details.topiclevel'),
                  defaultValue: '',
                  ref: register(),
                  name: 'topiclevel2',
                  error: errors[`topiclevel2`] ? 'Invalid Topic Level' : '',
                  onChange: (e: React.FormEvent<HTMLSelectElement>) => {
                    setTopicLevel2(e.currentTarget.value)
                  },
                  children: parseSelect(mainTopics, true),
                },
                1
              )}
            </Sideblock>
            <Sideblock spacing={{ top: 0.5 }}>
              {wrapInput(
                'description2',
                Textarea,
                {
                  placeholder: t('registration.details.description'),
                  defaultValue: '',
                  ref: register(),
                  name: 'description2',
                  disabled: !isTopicEmpty(topicLevel2),
                  rows: 3,
                  error: errors[`description2`] ? ' ' : '',
                  onChange: (e: React.FormEvent<HTMLSelectElement>) => {
                    setDescription2(e.currentTarget.value)
                  },
                },
                1
              )}
            </Sideblock>
          </Container>

          <Container>
            <Sideblock spacing={{ top: 0.5 }}>
              {wrapInput(
                'topiclevel3',
                Select,
                {
                  placeholder: t('registration.details.topiclevel'),
                  defaultValue: '',
                  ref: register(),
                  name: 'topiclevel3',
                  error: errors[`topiclevel3`] ? 'Invalid Topic Level' : '',
                  onChange: (e: React.FormEvent<HTMLSelectElement>) => {
                    setTopicLevel3(e.currentTarget.value)
                  },
                  children: parseSelect(mainTopics, true),
                },
                1
              )}
            </Sideblock>
            <Sideblock spacing={{ top: 0.5 }}>
              {wrapInput(
                'description3',
                Textarea,
                {
                  placeholder: t('registration.details.description'),
                  defaultValue: '',
                  ref: register(),
                  name: 'description3',
                  disabled: !isTopicEmpty(topicLevel3),
                  rows: 3,
                  error: errors[`description3`] ? ' ' : '',
                  onChange: (e: React.FormEvent<HTMLSelectElement>) => {
                    setDescription3(e.currentTarget.value)
                  },
                },
                1
              )}
            </Sideblock>
          </Container>

          <Container>
            <Sideblock>
              <Text size={18} spacing={{ bottom: 0.5 }}>
                {t('registration.details.othertopics')}
              </Text>
              {wrapInput(
                'othertopics',
                Textarea,
                {
                  placeholder: t('registration.details.othertopics'),
                  defaultValue: '',
                  ref: register(),
                  name: 'othertopics',
                  rows: 3,
                  error: errors[`othertopics`] ? ' ' : '',
                  onChange: (e: React.FormEvent<HTMLSelectElement>) => {
                    setOtherTopics(e.currentTarget.value)
                  },
                },
                1
              )}
            </Sideblock>
          </Container>

          <Heading type="h5" spacing={{ top: 3, bottom: 2 }}>
            {t('contactpage.header')}
          </Heading>

          <LiedContainer>
            <LiedBlock>
              <Text size={18} spacing={{ top: 1, bottom: 1 }}>
                {t('contactpage.leadcapture')} <sup>*</sup>
              </Text>
              {wrapInput(
                'leadCapture',
                Input,
                {
                  style: { width: '100%' },
                  placeholder: t('contactpage.emailaddress'),
                  name: 'leadCapture',
                  ref: register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t('contactpage.emailaddress'),
                    },
                  }),
                  error: errors[`leadCapture`] ? ' ' : '',
                },
                1
              )}

              <Text size={18} spacing={{ top: 1, bottom: 1 }}>
                {t('contactpage.contactperson')}
              </Text>
              {wrapInput(
                'contactPerson',
                Input,
                {
                  style: { width: '100%' },
                  placeholder: t('contactpage.emailcontactperson'),
                  name: 'contactPerson',
                  ref: register({
                    required: false,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t('contactpage.emailcontactperson'),
                    },
                  }),
                  error: errors[`contactPerson`] ? ' ' : '',
                },
                1
              )}
            </LiedBlock>
          </LiedContainer>

          <Text size={14} spacing={{ top: 2 }}>
            <sup>*</sup> {t('contactpage.required')}
          </Text>

          <ButtonContainer>
            <Button type="submit" disabled={sending}>
              {t('sendbutton')}
            </Button>
          </ButtonContainer>
        </>
      )}

      {props.status === 'sent' && (
        <>
          <Heading type="h2" style={{ marginTop: '50px' }}>
            {t('sent.header')}
          </Heading>
          <Paragraph>{t('sent.description')}</Paragraph>
          <Button
            icon={SieArrow}
            onClick={() => {
              props.onReset && props.onReset()
              setSending(false)
            }}
          >
            {t('sent.back')}
          </Button>
        </>
      )}

      {props.status === 'error' && (
        <>
          <Heading type="h2" style={{ marginTop: '50px' }}>
            {t('errors.header')}
          </Heading>
          <Paragraph>{t('errors.headerDescription')}</Paragraph>
          <Paragraph>{props.error && props.error}</Paragraph>
        </>
      )}
      <ReactTooltip effect="solid" className="customTooltip" />
      <ErrorBar
        visible={Object.keys(errors).length > 0 ? `${Math.random()}` : ''}
      >
        {parse(t('errors.bar'))}
      </ErrorBar>
    </Form>
  )
}

const ButtonContainer = styled.div({
  height: '28px',
  margin: '3rem 0',
})

const LiedBlock = styled.div({
  paddingRight: '1rem',
  gridColumn: '1/36',
  [mq[0]]: {
    gridColumn: '1/37',
  },
  [mq[4]]: {
    gridColumn: '1/28',
  },
})

const LiedContainer = styled.div(({ theme: { grid } }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${grid.columns},1fr)`,
}))

const BoxItem = styled.div({
  margin: '20px 0',
})

const Subblock = styled.div({
  marginTop: '1rem',
  paddingLeft: '2rem',
})

interface IContainer {
  noMargin?: boolean
}

const Container = styled.div<IContainer>(({ noMargin }) => ({
  margin: `${noMargin ? '0' : '1rem'} 0 0 0`,
  [mq[2]]: {
    display: 'flex',
  },
  position: 'relative',
}))
