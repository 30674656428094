import styled from '@emotion/styled'
import { TimeUtil, useOnClickOutside, useTranslations } from 'cuenect-web-core'
import dayjs from 'dayjs'
import { rgba } from 'emotion-rgba'
import i18n from 'i18next'
import React from 'react'
import { Button, SieClock, Text } from '../../atoms'
import { getAbbr } from './../../../utility'

interface TimezoneButtonProps {
  active?: boolean
}
export interface TimezoneSwitchProps {
  eventTimezone: string
  userTimezone: string
  onTimezoneChange?(checked: boolean): void
  timezoneChecked?: boolean
  short?: boolean
}

export const TimezoneSwitch: React.FC<TimezoneSwitchProps> = ({
  eventTimezone,
  userTimezone,
  timezoneChecked = false,
  onTimezoneChange,
  short = false,
}) => {
  const t = useTranslations('program')
  const [tooltipOpen, setTooltipOpen] = React.useState(false)
  const tooltipRef = React.useRef<HTMLDivElement>(null)
  useOnClickOutside(tooltipRef, () => setTooltipOpen(false))

  const yourTime = `${t('yourTime')} (${getAbbr(
    userTimezone,
    i18n.language,
    dayjs('2021-04-12')
  )})`
  const venueTime = `${t('venueTime')} (${getAbbr(
    eventTimezone,
    i18n.language,
    dayjs('2021-04-12')
  )})`

  return (
    <Container ref={tooltipRef}>
      {tooltipOpen && (
        <Tooltip>
          <TooltipButton
            plain
            small
            active={timezoneChecked}
            onClick={() => {
              setTooltipOpen(false)
              onTimezoneChange && onTimezoneChange(false)
            }}
          >
            {yourTime}
          </TooltipButton>
          <TooltipButton
            plain
            small
            active={!timezoneChecked}
            onClick={() => {
              setTooltipOpen(false)
              onTimezoneChange && onTimezoneChange(true)
            }}
          >
            {venueTime}
          </TooltipButton>
        </Tooltip>
      )}
      {short ? (
        <Button
          plain
          small
          active
          onClick={() => setTooltipOpen(!tooltipOpen)}
          className="chooseShort"
        >
          {t('chooseTimezoneShort')}
        </Button>
      ) : (
        <Button
          plain
          small
          icon={SieClock}
          iconAlign="right"
          onClick={() => setTooltipOpen(!tooltipOpen)}
        >
          {`${t('chooseTimezone')} ${timezoneChecked ? yourTime : venueTime}`}
        </Button>
      )}
    </Container>
  )
}

const Container = styled.div(
  ({ theme: { colors, fonts } }) => `
  position:relative;

  .chooseShort{
    font-size:14px;
  }

 `
)

const Tooltip = styled.div(
  ({ theme: { colors, fonts } }) => `
    position:absolute;
    bottom:40px;
    background: ${colors.lightBlue};
    border-bottom:1px solid ${colors.inputBorderHover};
    &:before{
      content:"";
      display:block;
      position:absolute;
      width:19px;
      height:19px;
      bottom:-11px;
      left:20px;
      background:${colors.lightBlue};
      border-bottom:1px solid ${colors.inputBorderHover};
      border-left:1px solid ${colors.inputBorderHover};
      transform:rotate(-45deg);
      z-index:0;

    }

 `
)
const TooltipButton = styled<TimezoneButtonProps>(Button)(
  ({ theme: { colors, fonts }, active }) => `
  font-family:${fonts.fontFamily};
  width:100%;
  padding:10px 40px;
  white-space:nowrap;position:relative;
  background: ${active ? rgba(colors.buttonSecondaryBackground, 0.5) : ''};
  &:hover{
    background: ${rgba(colors.buttonSecondaryBackground, 0.5)};
  }
 `
)
