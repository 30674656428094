import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import React from 'react'

/* interface SelectContainerProps {
  placeholder?: string | undefined
  focus?: boolean
} */

interface SelectProps extends HTMLSelectElement {
  error: string | undefined
  label?: string
}

interface SelectContainerProps {
  placeholder?: string | undefined
  label?: string | undefined
  focus?: boolean
  error?: boolean
}

export const Select = React.forwardRef<
  SelectProps,
  JSX.IntrinsicElements['select']
>(({ ...rest }, ref) => {
  const [placeholder, setPlaceholder] = React.useState<string | undefined>(
    rest.placeholder
  )
  const [focus, setFocus] = React.useState<boolean>(false)

  return (
    <SelectContainer
      placeholder={rest.placeholder}
      focus={focus}
      error={rest.error}
    >
      <SelectElement
        ref={ref}
        className={rest.error && rest.error !== '' ? 'error' : ''}
        {...rest}
        onFocus={() => {
          setFocus(true)
        }}
        onBlur={e => {
          setFocus(false)
        }}
      />
    </SelectContainer>
  )
})

export const SelectContainer = styled.div<SelectContainerProps>(
  ({ theme: { colors, fonts }, label, placeholder, focus, error }) => `
  position:relative;
  &:after{
    color:${
      error
        ? colors.error
        : focus
        ? colors.inputBorderHover
        : colors.inputBorder
    };
    content: "${label || placeholder}";
    position:absolute;
    font-size:12px;
    font-family: ${fonts.fontFamily};
    left:16px;
    top:2px;
  }

  &:before{
    position:absolute;
    right:12px;
    top:22px;
    content:"";width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: ${colors.linkOnDark} transparent transparent transparent;
  }
`
)

export const SelectElement = styled.select(
  ({ theme: { colors, fonts } }) => `
  -webkit-appearance: none;
  outline:none;
  border-radius:0;
  width:100%;
  font-family: ${fonts.fontFamily};
  font-size:18px;
  padding:19px 16px 10px 16px;
  border:none;
  outline:none;
  background:${colors.inputBackground};
  border-bottom:1px solid ${colors.inputBorder};
  color:${colors.inputText};
  box-sizing: border-box;
  &:disabled{
    border-bottom:none;
    color:${rgba(colors.inputText, 0.5)};
  }
  &:enabled:hover, &:enabled:focus{

    background:${colors.inputBackgroundHover};
    border-bottom:1px solid ${colors.inputBorderHover};
  }
  &::placeholder{
    transform:translateY(-5px);
    color:${colors.inputBorder}
  }
  &:focus{
    background:${colors.inputBackgroundActive};
    &::placeholder{font-size:0;  }
  }
  &.error{
    background:${rgba(colors.error, 0.2)};
    border-bottom:1px solid ${colors.error};

  }

  option, optgroup { -webkit-appearance: none; background:${colors.lightBlue};
  font: -moz-pull-down-menu;
}

  `
)
