import { TimeUtil } from 'cuenect-web-core'
import React from 'react'
interface State {
  userTimezone: string | null | undefined
  eventTimezone: string | null
  onTimezoneChange(checked: boolean): void
  timezoneChecked?: boolean
}

const defaultState: State = {
  userTimezone: TimeUtil.getUsersTimezone(),
  eventTimezone: '',
  onTimezoneChange: () => false,
  timezoneChecked: false,
}

export interface TimezoneAction extends State {
  type: 'CHANGE_TIMEZONE'
  state?: State
}

const reducer: React.Reducer<State, TimezoneAction> = (state, action) => {
  switch (action.type) {
    case 'CHANGE_TIMEZONE':
      return {
        ...state,
        timezoneChecked: action.timezoneChecked,
      }
    default:
      return {}
  }
}

export const TimezoneContext = React.createContext<{
  state: State
  dispatch: React.Dispatch<VodAction>
}>({ state: defaultState, dispatch: () => null })

export const TimezoneContextProvider: React.FC = props => {
  const [state, dispatch] = React.useReducer(reducer, defaultState)

  return (
    <TimezoneContext.Provider value={{ state, dispatch }}>
      {props.children}
    </TimezoneContext.Provider>
  )
}

export const useTimezoneContext = () => React.useContext(TimezoneContext)
