import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import React from 'react'
import { IconType } from 'react-icons'
import { getSpacingString, ISpacing } from '../../../utility/styleProps'

declare type BtnProps = JSX.IntrinsicElements['button']
export interface ButtonProps extends BtnProps {
  icon?: IconType
  spacing?: ISpacing
  iconAlign?: 'left' | 'right'
  hero?: boolean
  secondary?: boolean
  tertiary?: boolean
  plain?: boolean
  small?: boolean
  tag?: boolean
  asIcon?: boolean
  width?: string
  active?: boolean
  colored?: boolean
  menuItem?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  icon: Icon,
  iconAlign = 'left',
  children,
  type = 'button',
  ...rest
}) => {
  return (
    <ButtonContainer type={type} {...rest}>
      {Icon && iconAlign === 'left' && <Icon className="icon icon-left" />}
      {children}
      {Icon && iconAlign === 'right' && <Icon className="icon icon-right" />}
    </ButtonContainer>
  )
}

export const ButtonContainer = styled.button<ButtonProps>(
  ({
    theme: { colors, fonts },
    hero,
    secondary,
    tertiary,
    asIcon,
    plain,
    small,
    tag,
    spacing,
    width,
    active,
    colored,
    menuItem,
  }) => {
    let btnStyles = {
      color: colors.buttonText,
      fontFamily: fonts.fontFamilyBlack,
      fontWeight: 400,
      background: colors.buttonBackground,
      marginTop: getSpacingString(spacing?.top),
      marginRight: getSpacingString(spacing?.right),
      marginBottom: getSpacingString(spacing?.bottom),
      marginLeft: getSpacingString(spacing?.left),
      cursor: 'pointer',
      outline: 'none',
      border: 'none',
      padding: '18px 51px 15px 51px',
      fontSize: '20px',
      display: 'flex',

      // display: 'flex',
      alignItems: 'center',
      '&:hover': {
        background: colors.buttonActiveBackground,
      },
      '&:active': {
        background: colors.buttonBackground,
      },
      '&:disabled': {
        pointerEvents: 'none',
        color: colors.buttonTextDisabled,
        background: colors.buttonBackgroundDisabled,
      },
      svg: { fill: colors.link, width: '24px', height: '24px' },
    }
    if (asIcon) {
      return {
        ...btnStyles,
        background: 'transparent',
        padding: 0,
        '&:hover': {
          background: 'transparent',
          svg: { fill: colors.buttonSecondaryBackground },
        },
        '&:active': {
          background: 'transparent',
          svg: { fill: colors.buttonSecondaryBackground },
        },
      }
    }
    if (hero) {
      btnStyles = { ...btnStyles, padding: '22px 63px 18px 63px' }
    }
    if (secondary) {
      btnStyles = {
        ...btnStyles,
        border: `1px solid ${colors.buttonSecondaryBackground}`,
      }
    }

    if (width) {
      btnStyles = {
        ...btnStyles,
        width: width,
      }
    }

    if (secondary || tertiary) {
      btnStyles = {
        ...btnStyles,
        background: 'transparent',
        color: colors.buttonSecondaryBackground,
        '&:hover': {
          background: `${rgba(colors.buttonSecondaryBackground, 0.12)}`,
        },
        '&:active': {
          background: `${rgba(colors.buttonSecondaryBackground, 0.2)}`,
        },
        svg: {
          fill: colors.buttonSecondaryBackground,
          '&.icon-right': {
            paddingLeft: '5px',
          },
          '&.icon-left': {
            paddingRight: '5px',
          },
        },
      }
    }

    if (plain) {
      btnStyles = {
        ...btnStyles,
        color: active ? colors.inputBorderHover : colors.text,
        background: 'none',
        '&:hover': {
          background: 'none',
          opacity: 0.8,
        },
        '&:active': {
          background: 'none',
        },
      }
    }

    if (tag) {
      btnStyles = {
        ...btnStyles,
        fontSize: '14px',
        color: active ? colors.text : colors.inputBorderHover,
        fontFamily: fonts.fontFamily,
        padding: '10px 20px',
        borderRadius: '999px',
        background: active
          ? `${rgba(colors.buttonSecondaryBackground, 0.5)}`
          : 'none',
        border: `1px solid ${colors.inputBorderHover}`,
        '&:hover': {
          background: `${rgba(colors.buttonSecondaryBackground, 0.2)}`,
        },
        '&:active': {
          color: colors.text,
          background: `${rgba(colors.buttonSecondaryBackground, 0.5)}`,
        },
      }
    }
    if (small) {
      btnStyles = {
        ...btnStyles,
        fontSize: '16px',
        padding: '0',

        svg: {
          width: '16px',
          height: '16px',
          '&.icon-right': {
            paddingLeft: '5px',
          },
          '&.icon-left': {
            paddingRight: '5px',
          },
        },
      }
    }

    if (menuItem) {
      btnStyles = {
        ...btnStyles,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: fonts.fontFamily,
        height: '50px',
        fontSize: '16px',
        color: 'white',
        background: active
          ? rgba(colors.buttonSecondaryBackground, 0.2)
          : colors.bodyBackground,
        borderBottom: '0.1rem #707080 solid',
        padding: '0 15px 0 40px',
        '&:hover': {
          background: `${rgba(colors.buttonSecondaryBackground, 0.2)}`,
        },
        svg: {
          width: '16px',
          height: '16px',
          '&.icon-right': {
            paddingLeft: '5px',
          },
          '&.icon-left': {
            paddingRight: '5px',
          },
        },
      }
    }

    return btnStyles
  }
)
