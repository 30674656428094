import styled from '@emotion/styled'
import { TimeUtil } from 'cuenect-web-core'
import { Player } from 'furioos-sdk'
import React from 'react'
import { Button } from '../button'

const USER_MAX_TIMEOUT = 10800000 // 3h
let inter = null

export const Furioos = () => {
  const frameRef = React.useRef()
  const [initTime, setInitTime] = React.useState(TimeUtil.getNow())
  const [player, setPlayer] = React.useState()
  const options = {
    whiteLabel: true,
    hideToolbar: false,
    hideTitle: true,
    hidePlayButton: false,
  }

  const resetTimer = () => {
    console.log('RESET TIMER')
    setInitTime(TimeUtil.getNow())
  }

  const checkResetTimeout = (pl) => {
    console.log(
      'checkResetTimeout',
      TimeUtil.getNow().diff(initTime),
      TimeUtil.getNow().diff(initTime) < USER_MAX_TIMEOUT
    )
    if (TimeUtil.getNow().diff(initTime) < USER_MAX_TIMEOUT) {
      console.log('ACTIVATE USER')
      pl.setUserActive()
    }
  }

  React.useEffect(() => {
    //
    const _player = new Player(
      process.env.GATSBY_PAYER_ID,
      'furioos-container',
      options
    )
    setPlayer(_player)
    console.log("_player", _player)
    _player.onAppStart(()=>{
      console.log(">>>>>>>> appStarted")

    })
    _player.onUserActive(function () {
      // Implement your own code.
      console.log(">>>>>>>> onUserAcctive")
      //checkResetTimeout()
    })

    _player.onUserInactive(function () {
      // Implement your own code.
      console.log(">>>>>>>> onUserInactive")
      //_player?.setUserActive()
      checkResetTimeout(_player)
    })

   
  }, [])

  React.useEffect(()=>{
    if(inter){
      clearInterval(inter)
    }
    inter =  setInterval(()=>{
      console.debug("CNT", TimeUtil.getNow().diff(initTime), USER_MAX_TIMEOUT)
          }, 1000)
  }, [initTime])

  React.useEffect(() => {
    document
      .getElementById('furioos-sdk-iframe')
      ?.addEventListener('mouseout', () => {
        console.log('RESET TIMER')
        setInitTime(TimeUtil.getNow())
      })
  }, [frameRef])

  return <FurioosContainer id="furioos-container"></FurioosContainer>
}

const FurioosContainer = styled.div(
  ({ theme: { colors, grid } }) => `
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  iframe{
    border:0;
    width:100%;
    height:100%;
  }
`
)

const FurioosContainerDetection = styled.div(
  ({ theme: { colors, grid } }) => `
  position:fixed;
  pointer:none;
  background:red;
  opacity:.5;
  width:100vw;
  height:100vh;
  
    pointer-events:auto;

  
`
)
