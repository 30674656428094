import { v4 as uuidv4 } from 'uuid'
import { IHm21Appointment, IHm21DialogData } from '../interfaces/dialogdata'

import { topics } from './../../static/assets/appointments/expert_topics_appointment.json'
const addUuid = (src: AppointmentTopicProps[]) =>
  src.map(e => {
    let ret = { ...e, uuid: uuidv4() }
    if (e.children) {
      ret = { ...ret, children: addUuid(e.children) }
    }

    return ret
  })

const uuidTopics: AppointmentTopicProps[] = addUuid(topics)

interface AppointmentTopicProps {
  label: string
  business?: string
  businessUnit?: string
  position?: string | null
  uuid?: string
  children?: AppointmentTopicProps[]
}

export interface Topic extends Pick<AppointmentTopicProps, 'label' | 'uuid'> {}

export class DialogAppointmentTopics {
  // {"description": "", "fields": ["key": "value", …]}
  public static parseFormData = (
    form: IHm21DialogData,
    appointment: IHm21Appointment
  ) => {
    const { name } = appointment

    const parsedForm = { ...form }
    parsedForm.followUp = (new Object(form.followUp) as string[]).join(', ')

    return {
      description: name,
      fields: parsedForm,
    }
  }
}
