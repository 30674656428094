import { AxiosResponse } from 'axios'
import { CloudfrontEntry } from '../types'
import { showroomConfig } from './../config'
import { HttpService } from './http'

export interface ShowroomData {
  experts: ShowroomItem[]
  guidedTours: ShowroomItem[]
}

export interface ShowroomDataConfig {
  language: string
}

export interface ShowroomItem {
  slug: string
  label: string
  video: string
  hotspot?: {
    src: string
    map: string
  }
}

export class ShowroomService {
  public static async getData(
    config: ShowroomDataConfig
  ): Promise<ShowroomData | undefined> {
    const result: AxiosResponse | false = await HttpService.get({
      url: `${showroomConfig.url}`,
    })
    if (!result) {
      return
    }
    console.log('data', result.data)
    const transformer = new ShowroomTransformer(result.data, config)

    return transformer.parseData()
  }
}

export class ShowroomTransformer {
  private config: ShowroomDataConfig
  private data: CloudfrontEntry[]
  private baseTree: CloudfrontEntry | undefined
  private language: string
  public constructor(data: CloudfrontEntry[], config: ShowroomDataConfig) {
    this.data = data
    this.config = config
    this.baseTree = this.data.find(
      ({ ContentType }) => ContentType === 'HM21-VideoMicrosite'
    )

    this.language = this.config.language === 'de' ? 'de-DE' : 'en-US'
  }

  public parseData() {
    console.log(JSON.stringify(this.getGuidedToursVideos()))

    return {
      experts: this.getExpertVideos(),
      guidedTours: this.getGuidedToursVideos(),
    }
  }

  public getExpertVideos() {
    const node = this.baseTree?.ContentFieldValues.expertVideos[this.language]
    const ret: ShowroomItem[] = []
    node.map((uid: string) => {
      const add = this.parseExpertNode(uid)
      if (add) {
        ret.push(add)
      }
    })

    return ret
  }

  public getGuidedToursVideos() {
    return guidedTourVideos

    const node = this.baseTree.ContentFieldValues.recordedGuidedTours[
      this.language
    ]
    const ret: ShowroomItem[] = []
    node.map(uid => {
      const add = this.parseGuidedNode(uid)
      if (add) {
        ret.push(add)
      }
    })

    return ret
  }

  public parseExpertNode(id: string): ShowroomItem | undefined {
    const d = this.data.find(({ Uid }) => Uid === id)

    if (!d) {
      return
    }

    const mappedData = mapData.find(
      ({ uid }) => uid[this.config.language] === id
    )

    if (!mappedData) {
      console.log('NOT FOUND', id)

      return
    }

    return {
      slug: d.Uid,
      label: d.ContentFieldValues.name[this.language],
      video: `${showroomConfig.assets}${d.AssetDataSet.AssetFileProperties.FileHash}`,
      hotspot: mappedData.hotspot,
    }
  }

  public parseGuidedNode(id: string): ShowroomItem | undefined {
    const d = this.data.find(({ Uid }) => Uid === id)

    return {
      slug: d.Uid,
      label: d.ContentFieldValues.name[this.language],
      video: `${showroomConfig.assets}${d.AssetDataSet.AssetFileProperties.FileHash}`,
    }
  }
}

const guidedTourVideos = [
  {
    slug: 'pw4n34ibgt7uahzjftyro7uwfpnvip4acv4mk',
    label: 'German',
    video:
      'https://hm21.cdn.cuenect.online/videos/transcoded/5000_Digital_enterprise_virtual_experience_tour2021_DE_v02_VirtualWorld/hls.m3u8',
  },
  {
    slug: 'bibglcrvu6jw657dlogfg5jl2hp6aczutn3rs',
    label: 'Italian',
    video:
      'https://hm21.cdn.cuenect.online/videos/transcoded/ITA_revisione_2-5000_Digital_enterprise_virtual_experience_tour2021/hls.m3u8',
  },
  {
    slug: 'rogowqqcdmu6lcjb7bh2cvbkkeszffnalcoes',
    label: 'Russian',
    video:
      'https://hm21.cdn.cuenect.online/videos/transcoded/Digital_enterprise_hm21_russisch/hls.m3u8',
  },
  {
    slug: 'ejnpmqh7x6ib7hmqiakjbiijsbqndu6wjipki',
    label: 'English',
    video:
      'https://hm21.cdn.cuenect.online/videos/transcoded/5000_Digital_enterprise_virtual_experience_tour2021_v02_VirtualWorld/hls.m3u8',
  },
  {
    slug: '3h2mnxmba6nqm3nxh27po3arzmknymytp3yku',
    label: 'Portuguese',
    video:
      'https://hm21.cdn.cuenect.online/videos/transcoded/5000_Digital_enterprise_virtual_experience_tour2021_PTBR_1/hls.m3u8',
  },
  {
    slug: 'igvn3p2ys7xethjjrdmp57cgplko6qzi44mve',
    label: 'Chinese',
    video:
      'https://hm21.cdn.cuenect.online/videos/transcoded/5000_Digital_enterprise_virtual_experience_tour2021_v02_YouTube_H264_Highbitrate_CH/hls.m3u8',
  },
  {
    slug: 'iy72wk73f4z44usz4oahbkznom7qjjiiyeeao',
    label: 'French',
    video:
      'https://hm21.cdn.cuenect.online/videos/transcoded/5000_Digital_enterprise_virtual_experience_tour2021_VF_STTF_10MBPS__SiemensRoman_FR/hls.m3u8',
  },
  {
    slug: 'umvrc2e2axq7sshbftwetwh2z67dvse3yowr6',
    label: 'Spanish',
    video:
      'https://hm21.cdn.cuenect.online/videos/transcoded/5000-5_Guided_Tour_Spanish/hls.m3u8',
  },
  {
    slug: 'wowqlumakxnr4q2j5iv24nyb3iro26r4ezod2',
    label: 'Indonesian',
    video:
      'https://hm21.cdn.cuenect.online/videos/transcoded/HM_21_Guide_Tour-Dubbing_Sub_Indonesien/hls.m3u8',
  },
  {
    slug: 'olopuvvhnli6rl4xybid3o4bdblhwzerh2vto',
    label: 'Japanese',
    video:
      'https://hm21.cdn.cuenect.online/videos/transcoded/JP_5000_Digital_enterprise_virtual_experience_tour2021_v02_YouTube_Japanisch/hls.m3u8',
  },
]

const mapData = [
  {
    slug: 'highlight',
    uid: {
      en: 'd3mgpo3eqcw72wwlsnzori4rfd5odaxrtta6g',
      de: '3vfcj3zkssf3cjxitlwlbigeub5i6enyrd222',
    },
    hotspot: {
      src: '/assets/showroom/highlight.png',
      map:
        'M533,553c0,0,20.7,22.5,56,27c35.3,4.5,59.6,1.1,85-7c25.4-8.1,109-35,109-35s44.5-13.8,54-51c9.5-37.2-18.3-61.2-28-68c-9.7-6.8-39.6-21.6-64-23c-10.8-0.6-25.1-1.8-44,1c-18.9,2.8-125,35-125,35s-17.5,6-34,18 c-16.5,12-26.4,27.9-31,53C506.4,528.1,528,548.9,533,553z',
    },
  },
  {
    slug: 'vertical_01',
    uid: {
      en: 'pnsf55y56jk3wzfl3a5fodhdu56f2zj7t6ipg',
      de: 'zbthnfaexley2vqneagrkp6afc2qgllkut3vy',
    },
    hotspot: {
      src: '/assets/showroom/vertical_01.png',
      map:
        'M486,415c0,0,7.5,6.7,18,7c10.5,0.3,14.9-0.1,20-2c5.1-1.9,38-11,38-11s15-7.8,12-20c-3-12.2-15.1-16.4-22-17c-6.9-0.6-10.2-0.1-17,1c-6.8,1.1-40,11-40,11s-8.4,4-13,11C477.4,402,482.7,413.1,486,415z',
    },
  },
  {
    slug: 'vertical_02',
    uid: {
      en: 'g6w74lcjofgpjztrldg6bejzgrtihdhkpprgk',
      de: 'fo2ptznjfrd3envwrlgiymmdivx2topbfxjti',
    },
    hotspot: {
      src: '/assets/showroom/vertical_02.png',
      map:
        'M651,365c0,0,4.8,8.3,22,10c17.2,1.7,53-12,53-12s16.8-5.6,8-26c-2.5-5.8-19-10.1-27-9c-8,1.1-35.2,6.5-48,12C646.2,345.5,646.3,358.8,651,365z',
    },
  },
  {
    slug: 'vertical_03',
    uid: {
      en: 'e6nro3hufe5kuvkajhmxfr6wduk64sts3lsuy',
      de: 'w3mj6tbzdfewboheyjeqd4qqqjesvo3c2im2q',
    },
    hotspot: {
      src: '/assets/showroom/vertical_03.png',
      map:
        'M600,654c0,0,10.3,28,44,22c9.7-1.7,52-18,52-18s24.6-15.9,4-42c-17.7-22.4-82,7-82,7S599.3,628.4,600,654z',
    },
  },
  {
    slug: 'vertical_04',
    uid: {
      en: 's7zdxehcqt6jaxbwfwd2pbrosooltfoj463dg',
      de: 'cakmaizp5z7pkeicphv7kywp25yz34c5yx55a',
    },
    hotspot: {
      src: '/assets/showroom/vertical_04.png',
      map:
        'M794,595c0,0,11.1,24.1,46,14c7.3-2.1,74.8-10.9,47-51c-3.4-4.9-18.5-11.3-35-11c-7.6,0.1-52,18-52,18S783.8,575,794,595z',
    },
  },
  {
    slug: 'expert_01',
    uid: {
      en: 'p3zvqogizv2jlamj4auoapk3ddfsvff4waiiy',
      de: 'eialv6nozkwyyy6xy4o6yvwmfcir4ieuvqnzu',
    },
    hotspot: {
      src: '/assets/showroom/expert_01.png',
      map: 'M657,260l92,9l53-49l-39-54l-87-7l-54,45L657,260z',
    },
  },
  {
    slug: 'expert_02',
    uid: {
      en: 'o25ot4lcoldbsoy6r7patpozkmkd6b2be2sok',
      de: 'jkwar7xlaferfvwtiwgp7w7h4y7if2m4zyvu2',
    },
    hotspot: {
      src: '/assets/showroom/expert_02.png',
      map: 'M761,274l41,62l99,9l50-54l-46-58l-91-8L761,274z',
    },
  },
  {
    slug: 'expert_03',
    uid: {
      en: 'rfz4oh7ov5efnfwysg64kmuzdpi2356oa6kxg',
      de: '73ilijyoytkeq45xemmoap6zywchklbvmbunw',
    },
    hotspot: {
      src: '/assets/showroom/expert_03.png',
      map: 'M913,351l50-54l97,9l54,64l-47,59l-104-10L913,351z',
    },
  },
  {
    slug: 'expert_04',
    uid: {
      en: 'k3doqktladi4w7fconvwdwe5o2cezdndq6c6o',
      de: 'bax56onmdzu362cofgtvw4tbqfcqnijsscxfy',
    },
    hotspot: {
      src: '/assets/showroom/expert_04.png',
      map: 'M908,492l54-63l105,10l58,75l-51,71l-113-13L908,492z',
    },
  },
  {
    slug: 'expert_05',
    uid: {
      en: 'rtppgidujljwpdv26htymy5mckkwe6qekaof6',
      de: 'ebsn3q5jybs63ao2ida2qyh4u2wjawdjygq4s',
    },
    hotspot: {
      src: '/assets/showroom/expert_05.png',
      map: 'M901,660l60-75l114,12l64,89l-55,84l-125-15L901,660z',
    },
  },
  {
    slug: 'expert_06',
    uid: {
      en: 'xn27xcrwxpxrylzdjj73k7lmqskwpvdsw7xke',
      de: 'xo6lwfqwp6lhx5s5ujyik3tdt732cbmot6x4i',
    },
    hotspot: {
      src: '/assets/showroom/expert_06.png',
      map: 'M701,733l69-81l117,14l58,95l-66,90l-128-16L701,733z',
    },
  },
  {
    slug: 'expert_07',
    uid: {
      en: 'rgccovbxcq2zopsuiliwx3mo2e6grj2xz3wtq',
      de: 'js2q3ppv52dc7ldlfch432srmxhdcdfcgpmn4',
    },
    hotspot: {
      src: '/assets/showroom/expert_07.png',
      map: 'M484,810l80-87l120,15l50,104l-79,96l-130-18L484,810z',
    },
  },
  {
    slug: 'expert_08',
    uid: {
      en: 'tcrsiv2kz6kr44i63stsha5asm5yjdyqe4kvu',
      de: 'flsvoct5xgezmyifm4utq6uaytyb4nm5tsgii',
    },
    hotspot: {
      src: '/assets/showroom/expert_08.png',
      map: 'M317,686l32,99l121,15l81-85l-40-92l-112-13L317,686z',
    },
  },
  {
    slug: 'expert_09',
    uid: {
      en: 'd64o4doat2fwfa4wm3jdxqfhxpshayezriysa',
      de: 'ksvoqgyb2omhstpynojzifhxnd7z3gn4u53um',
    },
    hotspot: {
      src: '/assets/showroom/expert_09.png',
      map: 'M170,577l23,88l113,13l82-76l-30-82l-105-12L170,577z',
    },
  },
  {
    slug: 'expert_10',
    uid: {
      en: 'sqt45gk4hjhpmg7imwzys4s3k57e2qadmzmzs',
      de: 'uvgawe73z2ff2mnxhi623efnveygnoljqjrf6',
    },
    hotspot: {
      src: '/assets/showroom/expert_10.png',
      map: 'M235,423l23,74l104,11l74-64l-29-69l-98-10L235,423z',
    },
  },
  {
    slug: 'expert_11',
    uid: {
      en: 'efjf4yu6wyfp46ce2hzgimih3n6bjp5hs2lnw',
      de: 'ryoxzlhubjwpqfibvbhbzmg2fc2vxoatcjflo',
    },
    hotspot: {
      src: '/assets/showroom/expert_11.png',
      map: 'M289,292l25,64l96,9l67-55l-30-60l-90-8L289,292z',
    },
  },
  {
    slug: 'expert_12',
    uid: {
      en: 'qxluendkcpi2kiifwtrsu5lb6lv7lds332osm',
      de: 'lkplzitgseu6ak7n5k276sua75vlrl4eewmgy',
    },
    hotspot: {
      src: '/assets/showroom/expert_12.png',
      map: 'M460,247l30,60l95,8l59-52l-34-56l-89-8L460,247z',
    },
  },
]
