import styled from '@emotion/styled'
import {
  isLivestreamSimulatorState,
  LiveStreamSimulator,
  LivestreamSimulatorState,
  LivestreamSimulatorStateType,
  LiveVideo,
  StateId,
  TimeUtil,
} from 'cuenect-web-core'
import parse from 'html-react-parser'
import i18n from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ProgramCategory,
  ProgramEvent,
  ProgramService,
} from '../../../api/program'
import { Paragraph } from '../../atoms'
import { Button, Heading, ProgramEntry, Video } from './../../'
import { eventConfig, isPostEventFirst } from './../../../config'
import {
  LivestreamTransformer,
  ProgramTransformer,
} from './../../../transformers'
import { mq, useTimeRange } from './../../../utility'
import cq from './../../../utility/coreQuery'
export interface LiveStreamContainerProps {
  source: string
  slug?: string
}

export const LiveStreamContainer: React.FC<LiveStreamContainerProps> = ({
  source,
  slug,
}) => {
  const postEventFirst = isPostEventFirst()
  const { t } = useTranslation('live')
  const videoRef = React.useRef<LiveStreamSimulatorRef>()

  const [streamData, setStreamData] = React.useState<LiveVideo[]>()
  const [loading, setLoading] = React.useState(true)
  const [isFiller, setIsFiller] = React.useState(false)
  const [currentSessionId, setCurrentSessionId] = React.useState('')
  const [nextSessionId, setNextSessionId] = React.useState('')
  const [entriesRaw, setEntriesRaw] = React.useState<
    ProgramEvent[] | undefined
  >()

  const [currentSession, setCurrentSession] = React.useState<ProgramEvent>()
  const [nextSession, setNextSession] = React.useState<ProgramEvent>()

  const displayTimezone = TimeUtil.getUsersTimezone() || eventConfig.timezone

  const getContent = async () => {
    const apiResponse: ProgramEvent[] = await ProgramService.getProgram(
      source,
      slug,
      true
    )
    setEntriesRaw(apiResponse)
    setStreamData(LivestreamTransformer.transform({ apiResponse }))
    setLoading(false)
  }

  const getOverlay = () => {
    if (useTimeRange('2021-04-01T00:00:00Z', '2021-04-12T23:59:59Z')) {
      return 'preEvent'
    }
    if (useTimeRange('2021-04-13T00:00:00Z', '2021-04-13T06:00:00Z')) {
      return 'startSoon'
    }
    if (useTimeRange('2021-04-13T17:50:00Z', '2021-04-14T00:00:00Z')) {
      return 'liveEventEnded'
    }
    if (useTimeRange('2021-04-14T00:00:00Z', '2021-04-14T06:00:00Z')) {
      return 'startSoon'
    }
    if (useTimeRange('2021-04-14T17:50:00Z', '2021-04-15T00:00:00Z')) {
      return 'liveEventEnded'
    }
    if (useTimeRange('2021-04-15T00:00:00Z', '2021-04-15T06:00:00Z')) {
      return 'startSoon'
    }
    if (useTimeRange('2021-04-15T17:50:00Z', '2021-12-15T23:59:59Z')) {
      return 'eventEnded'
    }

    return 'nextSoon'
  }

  const handleStateChange = (state: LivestreamSimulatorState) => {
    setIsFiller(
      state.stateId === StateId.FILLER || state.stateId === StateId.END
    )
    if (
      isLivestreamSimulatorState<LivestreamSimulatorStateType.LS_STATE_LOADING>(
        state,
        StateId.LOADING
      )
    ) {
      setCurrentSessionId(
        state.currentVideo.id &&
          useTimeRange(
            state.currentVideo.startUtc.format(),
            state.currentVideo.endUtc.format()
          )
          ? state.currentVideo.id
          : ''
      )
      setNextSessionId(
        state.nextVideos.length > 0 ? state.nextVideos[0].id : ''
      )
    }

    if (
      isLivestreamSimulatorState<LivestreamSimulatorStateType.LS_STATE_INITIAL>(
        state,
        StateId.INITIAL
      )
    ) {
      setNextSessionId(state.videos.length > 0 ? state.videos[0].id : '')
    }

    if (
      isLivestreamSimulatorState<LivestreamSimulatorStateType.LS_STATE_END>(
        state,
        StateId.END
      )
    ) {
      console.log('END')

      setCurrentSessionId('')
      setNextSessionId('')
    }
  }

  React.useEffect(() => {
    if (entriesRaw) {
      if (currentSessionId) {
        setCurrentSession(
          ProgramTransformer.getSessionById(currentSessionId, entriesRaw, {
            displayTimezone,
            showAppointment: true,
          })
        )

        console.log(
          'CURRENT',
          ProgramTransformer.getSessionById(currentSessionId, entriesRaw, {
            displayTimezone,
            showAppointment: true,
          })
        )
      }
      if (nextSessionId) {
        setNextSession(
          ProgramTransformer.getSessionById(nextSessionId, entriesRaw, {
            displayTimezone,
            showAppointment: true,
          })
        )
      }
    }
  }, [currentSessionId, nextSessionId, entriesRaw])

  const parseCustom = (session: IProgramEntry) => (
    <>
      {TimeUtil.convertToTimeZone(session.startUtc, displayTimezone).format(
        'HH:mm'
      )}
      {session.endUtc &&
        ` - ${TimeUtil.convertToTimeZone(
          session.endUtc,
          displayTimezone
        ).format('HH:mm')}`}
    </>
  )

  React.useEffect(() => {
    getContent()
  }, [])

  const getVideoOverlay = () => {
    return isFiller ? (
      <LiveStreamOverlay type="h4">
        {parse(t(`overlay.${getOverlay()}`))}
      </LiveStreamOverlay>
    ) : null
  }

  return (
    <LiveStreamPlayerOuter>
      {!loading && (
        <>
          <LiveStreamPlayer>
            <LiveStreamPlayerRatio>
              <LiveStreamSimulator
                player={Video}
                ref={videoRef}
                startVideoType="autoplay_muted_topcontrols"
                videos={streamData}
                fadeOut
                loadingSpinnerDealyMs={4000}
                fadeToBlackMs={650}
                onStateChange={handleStateChange}
                videoProps={{
                  renderTopLeft: getVideoOverlay,

                  playerName: 'Live',
                  trackingTitle: currentSession?.title
                    ? `${currentSession?.title}||${JSON.stringify({
                        uid: currentSession.id,
                      })}`
                    : 'Filler Video',
                }}
                fillerVideo={
                  'https://hm21.cdn.cuenect.online/videos/transcoded/DI-Loop_BG-Ani_slow_Wide_FHD_210301-1_H264_5Mbit_VBR_1/hls.m3u8'
                }
                endVideo={
                  'https://hm21.cdn.cuenect.online/videos/transcoded/DI-Loop_BG-Ani_slow_Wide_FHD_210301-1_H264_5Mbit_VBR_1/hls.m3u8'
                }
              />
            </LiveStreamPlayerRatio>
          </LiveStreamPlayer>
          <LiveStreamTeaser>
            <div>
              <Heading type="h5">
                {t(`${postEventFirst ? 'teaserPostEvent' : 'teaser'}.header`)}
              </Heading>
              <Paragraph>
                {t(
                  `${postEventFirst ? 'teaserPostEvent' : 'teaser'}.description`
                )}
              </Paragraph>
              <Button
                onClick={() =>
                  postEventFirst
                    ? window.open(t(`teaserPostEvent.ctaLink`))
                    : (document.location.href = `/${i18n.language}/dialog#appointment`)
                }
              >
                {t(`${postEventFirst ? 'teaserPostEvent' : 'teaser'}.cta`)}
              </Button>
            </div>
          </LiveStreamTeaser>
        </>
      )}
      <Spacer />
      {currentSessionId !== '' &&
        currentSession &&
        !currentSession.hidden &&
        (currentSession.endUtc.isAfter(TimeUtil.getNowUtc()) ||
          (nextSession &&
            nextSession.startUtc.isSame(TimeUtil.getNowUtc(), 'day'))) && (
          <SessionsContainer>
            <Heading underlined type="h4">
              {t('currentPresentation')}
            </Heading>
            <ProgramEntry
              toggleOpen={true}
              renderCustom={() => parseCustom(currentSession)}
              {...currentSession}
            />
          </SessionsContainer>
        )}
      <Spacer />
      {nextSessionId !== '' &&
        nextSession &&
        !nextSession.hidden &&
        nextSession.startUtc.isSame(TimeUtil.getNowUtc(), 'day') && (
          <SessionsContainer>
            <Heading underlined type="h5">
              {!currentSession ? t('firstPresentation') : t('nextPresentation')}
            </Heading>
            <ProgramEntry
              renderCustom={() => parseCustom(nextSession)}
              {...nextSession}
            />
          </SessionsContainer>
        )}
    </LiveStreamPlayerOuter>
  )
}

const Spacer = styled.div`
  grid-column: 1/37;
  margin: 15px 0;
  ${mq[3]} {
    margin: 45px 0;
  }
`
const LiveStreamContainerOuter = styled.div(() => ``)

const LiveStreamPlayerOuter = styled.div(
  ({ theme: { grid } }) => ` display: grid;
  grid-template-columns: repeat(${grid.columns},1fr);
  grid-template-rows: 1fr;
  margin-top:50px;
  z-index:2000;

  ${mq[3]} {
    margin-top:0;
  }
  `
)
const LiveStreamOverlay = styled(Heading)(
  ({ theme: { grid } }) => `
    position:absolute;
    left:20px;
    right:20px;
    top:20px;
    bottom:20px;
    display:flex;
    align-items:center;
    justify-content:center;
    text-align:center;
    text-shadow: 0px 0px 4px rgba(0,0,0,0.81);
    font-size:14px;

    ${mq[1]} {
      font-size:32px;
    }

  `
)

const LiveStreamPlayer = styled.div(
  ({ theme: { colors } }) => `
  position:relative;
   background:black;
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
  grid-column: 1/37;
  ${cq('UnmuteButtonIcon')}{
    svg{ fill:${colors.buttonText}}
  }


  ${mq[3]} {
    grid-column: 1/25;
  }
  `
)

const LiveStreamPlayerRatio = styled.div(
  ({ theme: { colors } }) => `
 position:absolute;
 top:0;
 left:0;
 width:100%;
 height:100%;
 & > div > div:first-of-type {
   height:100%;
 }
 ${cq('video-Black')}{
  background:${colors.bodyBackground};
}
  `
)

const LiveStreamTeaser = styled.div(
  ({ theme: { grid } }) => `
  display:flex;
  flex-direction:column;
  justify-content:center;
  grid-column: 1/37;
  ${mq[3]} {
    grid-column: 27/37;
  }
  button{
    text-align:center;
  }
  `
)

const SessionsContainer = styled.div(
  ({ theme: { grid } }) => `
  grid-column: 3/34;
  & > div{
    flex-direction: column;
    & > div:nth-of-type(1){
      padding-top:10px;
    }
    & > div:nth-of-type(2){
      padding-top:15px;
      border-bottom:none;
      & > div > div:nth-of-type(2){
        display:none;
      }
    }
  }
  `
)
