import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import {
  SieFacebook,
  SieInstagram,
  SieLinkedin,
  SieTwitter,
  SieYoutube,
} from '../../atoms/icons'
import { mq } from './../../../utility/break'

export const SocialList: React.FC = () => {
  const { colors } = useTheme()

  return (
    <SocialHolder>
      <SocialHolderInner>
        <a target="_blank" href="https://twitter.com/siemens">
          <SieTwitter color={colors.link} size="24px" />
        </a>
        <a target="_blank" href="https://www.linkedin.com/company/siemens">
          <SieLinkedin color={colors.link} size="24px" />
        </a>
        <a target="_blank" href="https://www.facebook.com/Siemens">
          <SieFacebook color={colors.link} size="24px" />
        </a>
        <a target="_blank" href="https://www.youtube.com/user/Siemens">
          <SieYoutube color={colors.link} size="24px" />
        </a>
        <a target="_blank" href="https://www.instagram.com/siemens/">
          <SieInstagram color={colors.link} size="24px" />
        </a>
      </SocialHolderInner>
    </SocialHolder>
  )
}

const SocialHolder = styled.div(() => ({
  small: {
    fontWeight: 'bold',
    fontSize: '.85rem',
  },
}))
const SocialHolderInner = styled.div`
  margin-top: 0.5rem;
`
