const hostname = typeof window !== 'undefined' ? window.location.hostname : ''

const apiUri =
  hostname !== 'localhost'
    ? '/api/v1'
    : 'https://hm21.siemens.cuenect.online/api/v1'

export const apiConfig = {
  baseUrl:
    process.env.NODE_ENV === 'development'
      ? 'https://hm21.siemens.cuenect.online/api/v1'
      : '/api/v1',
  chatUrl: 'https://chat.cuenect.de',
  checkIn: `${apiUri}/users/check-in`,
  me: `${apiUri}/persons/me`,
  appointmentSlots: `${apiUri}/appointments/slots`,
  /*  appointmentSlots: `/mockdata/appointmentSlots.json`, */
  eventParticipation: `${apiUri}/events/participation`,
  appointments: `${apiUri}/appointments`,
  registerEvent: `${apiUri}/events`,

  register: `${apiUri}/appointments`,
  sales: `${apiUri}/sales`,

  dialogAppointment: `${apiUri}/appointments/feedback`,
}
export const CRISP_WEBSITE_ID = 'c379fe30-8c1f-431e-b49d-de9ac36e0d2b'
export const AUTH_ACTIVE = true
