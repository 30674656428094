import styled from '@emotion/styled'
import React from 'react'
import { mq } from './../../../utility/break'

export const LineContainer: React.FC<JSX.IntrinsicElements['div']> = ({
  children,
  ...rest
}) => {
  return (
    <LineContainerOuter {...rest}>
      <LineDecoration />
      <LineContent>{children}</LineContent>
    </LineContainerOuter>
  )
}
export default LineContainer

const LineContainerOuter = styled.div(({ theme: { grid } }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'stretch',
  marginLeft: `-${100 / grid.columns}vw`,
  marginTop: 0,
  marginBottom: 0,
}))

const LineDecoration = styled.div(({ theme: { colors } }) => ({
  width: '6px',
  background: colors.lineDecorator,
  position: 'absolute',
  left: 0,
  top: '.95rem',
  bottom: '.8rem',
}))

const LineContent = styled.div(({ theme: { grid } }) => ({
  marginLeft: `calc(${200 / grid.columns}vw - 6px)`,
  marginTop: 0,
  marginBottom: 0,
  '& h1': {
    margin: 0,
  },
  [mq[2]]: {
    marginLeft: `calc(${100 / grid.columns}vw - 6px)`,
  },
}))
