import styled from '@emotion/styled'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { Button, Heading, Paragraph, Select } from '../../atoms'
import { ShowroomItem } from './../../../api'
import { mq, useModalContext, useVodContext } from './../../../utility'

interface PreselectGuidedTourProps {
  videoSelector?: ShowroomItem[] | null
}

export const PreselectGuidedTour: React.FC<PreselectGuidedTourProps> = ({
  videoSelector,
}) => {
  const { dispatch: openVod } = useVodContext()
  const { dispatch: modalDispatch } = useModalContext()
  const { t } = useTranslation('showroom')
  const selectRef = React.useRef()

  const startVideo = () => {
    openVod({
      type: 'OPEN_VOD',
      src: videoSelector.find(({ slug }) => slug === selectRef.current.value)
        .video /* t('cta.guidedTours.src') */,
      track: t('cta.guidedTours.track'),
      trackingTitle: 'Guided Tour Video',
      playerName: 'Guided Tour',
      videoSelector,
    })
  }

  return (
    <Container>
      <Heading type="h4">{t('preselectLanguage.language')}</Heading>
      <Paragraph>{t('preselectLanguage.description')}</Paragraph>
      <Select
        placeholder=" "
        ref={selectRef}
        defaultValue={
          videoSelector.find(({ label }) => label === 'English').slug
        }
      >
        {videoSelector?.map(({ slug, label, video }) => (
          <option value={slug} key={slug}>
            {' '}
            {label}
          </option>
        ))}
      </Select>
      <Button onClick={() => startVideo()}>
        {t('preselectLanguage.play')}
      </Button>
    </Container>
  )
}
const Container = styled.div`
  max-width: 90vw;

  padding: 0 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    margin-bottom: 40px;
  }
  button {
    margin-top: 50px;
  }
  select {
    width: 200px;
  }
  ${mq[2]} {
    padding: 0 30px 30px;

    max-width: inherit;
    min-width: 760px;
    select {
      width: 320px;
    }
  }
`
