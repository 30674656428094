import { v4 as uuidv4 } from 'uuid'

import { topics } from './../../static/assets/appointments/expert_topics_appointment.json'
import { AppointmentFormData } from './../components/organisms/appointmentForm'
const addUuid = (src: AppointmentTopicProps[]) =>
  src.map(e => {
    let ret = { ...e, uuid: uuidv4() }
    if (e.children) {
      ret = { ...ret, children: addUuid(e.children) }
    }

    return ret
  })

const uuidTopics: AppointmentTopicProps[] = addUuid(topics)

interface AppointmentTopicProps {
  label: string
  business?: string
  businessUnit?: string
  position?: string | null
  uuid?: string
  children?: AppointmentTopicProps[]
}

export interface Topic extends Pick<AppointmentTopicProps, 'label' | 'uuid'> {}

export class AppointmentTopics {
  public static getMainTopics(): Topic[] {
    return uuidTopics.map(({ label, uuid }) => ({ label, uuid }))
  }
  public static getLevel2Topics(level2Uuid: string | null): Topic[] | null {
    return (
      uuidTopics
        .find(({ uuid }) => uuid === level2Uuid)
        ?.children?.map(({ label, uuid }) => ({ label, uuid })) || null
    )
  }

  public static getLevel2BusinessUnit(level2Uuid: string | null) {
    let found = null
    uuidTopics.forEach(({ children: lvl1 }) => {
      lvl1?.forEach(({ children: lvl2, uuid: lvl2Uuid, businessUnit }) => {
        if (lvl2Uuid === level2Uuid) {
          found = businessUnit || null
        }
      })
    })

    return found
  }

  public static getLevel3Topics(level3Uuid: string | null): Topic[] | null {
    let found = null
    uuidTopics.forEach(({ children: lvl1 }) => {
      lvl1?.forEach(({ children: lvl2, uuid: lvl2Uuid, label }) => {
        if (lvl2Uuid === level3Uuid) {
          found = lvl2 || null
        }
      })
    })

    return found
  }

  public static parseFormData = (data: AppointmentFormData) => {
    const {
      topicLevel1,
      topicLevel2,
      topicLevel3,
      topicLevel3_1,
      note,
      preferedDate,
      alternativeDate,
      alternativeSecondDate,
      country,
      meetingLanguage,
    } = data

    let business = ''
    let businessUnit: string[] = []
    let position = ''
    const titleTags = []
    let titleTagsAlt = []
    const level1 = uuidTopics.find(({ uuid }) => uuid === topicLevel1)
    let level2 = null
    let level3 = null
    let level3_1 = null
    if (level1) {
      titleTags.push(level1.label)
      const level1Tags = parseTags(level1)
      business = level1Tags.business
      businessUnit = level1Tags.businessUnit
      position = level1Tags.position

      if (topicLevel2) {
        level2 = level1.children.find(({ uuid }) => uuid === topicLevel2)
        if (level2) {
          titleTags.push(level2.label)
          const level2Tags = parseTags(level2)
          business = level2Tags.business
          businessUnit = level2Tags.businessUnit
          position = level2Tags.position
          if (topicLevel3) {
            level3 = level2.children.find(({ uuid }) => uuid === topicLevel3)
            if (level3) {
              titleTags.push(level3.label)
              const level3Tags = parseTags(level3)
              business = level3Tags.business
              businessUnit = level3Tags.businessUnit
              position = level3Tags.position
            }
          }
          if (topicLevel3_1) {
            level3_1 = level2.children.find(
              ({ uuid }) => uuid === topicLevel3_1
            )
            if (level3_1) {
              const level3_1Tags = parseTags(level3_1)
              titleTagsAlt = [...titleTags]

              if (level3) {
                titleTagsAlt.pop()

                business = `${business},${level3_1Tags.business}`
                businessUnit = [...businessUnit, ...level3_1Tags.businessUnit]
              } else {
                business = level3_1Tags.business
                businessUnit = level3_1Tags.businessUnit
              }
              titleTagsAlt.push(level3_1.label)
              position = level3_1Tags.position
            }
          }
        }
      }
    }

    const appointmentSlots = []
    if (preferedDate) {
      appointmentSlots.push(Number(preferedDate))
    }
    if (alternativeDate) {
      appointmentSlots.push(Number(alternativeDate))
    }
    if (alternativeSecondDate) {
      appointmentSlots.push(Number(alternativeSecondDate))
    }

    // Reduce Units
    const tags = [
      titleTags.join(' / '),
      titleTagsAlt && titleTagsAlt.join(' / '),
      ...[...new Set(businessUnit)].map(unit => `Business Unit: ${unit}`),
      ...[...new Set(business.split(','))].map(bu => `Business: ${bu}`),
      `#${meetingLanguage.toUpperCase()}`,
      `Country: ${country}`,
    ]

    return {
      description: note,
      proposals: appointmentSlots,
      tags: tags.filter(tag => tag !== ''),
    }
  }
}

const parseTags = (level: AppointmentTopicProps) => {
  let ret = {
    businessUnit: [],
    business: '',
    position: '',
  }

  if (level.businessUnit) {
    ret = { ...ret, businessUnit: level?.businessUnit.split(',') }
  }
  if (level.business) {
    ret = { ...ret, business: level.business }
  }
  if (level.position) {
    ret = { ...ret, position: level.position }
  }

  return ret
}
